export const styles = {
  hud: (time, opacity) => ({
    fontSize: "1.052vw",
    color: "white",
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5%",
    bottom: "0",
    width: "100%",
    height: "6%",
    position: "absolute",
    transition: "all ease-out 0.32s",
    background: "RGBA(0,0,0,0.7)",
  }),

  button: {
    background: "RGBA(0,0,0,0.0)",
    border: "none",
    color: "white",
    cursor: "pointer",
    display: "flex",
  },
};
