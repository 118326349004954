import React from "react";

const InformationCirculeOff = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
      />
      <svg
        width={width || "24"}
        height={height || "24"}
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.08162 1.08969C1.35987 0.795243 1.80337 1.29524 2.08162 1.58969L17.5816 17.5897C17.8599 17.8841 18.3599 18.2953 18.0816 18.5897C17.8034 18.8841 17.3599 18.3841 17.0816 18.0897L1.58162 2.08969C1.30337 1.79524 0.803374 1.38413 1.08162 1.08969Z"
          fill="white"
          stroke="white"
        />
      </svg>
    </svg>
  );
};

export default InformationCirculeOff;
