import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const ImageSlider = (props) => {
  const { horses, setHorseinfo, clickedHorse, raceData } = props;
  let backgroundImage = null;
  switch (raceData.track.id) {
    case 1:
      backgroundImage =
        "https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/fondo_fixture_sand.png";
      break;
    case 2:
      backgroundImage =
        "https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/fondo_fixture_grass.png";
      break;
    case 3:
      backgroundImage =
        "https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/fondo_fixture_dirt.png";
      break;
    default:
      backgroundImage =
        "https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/fondo_fixture_grass.png";
      break;
  }
  return (
    <div style={{ width: "100%", maxHeight: "100%", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          margin: "auto",
        }}
      >
        <img
          alt="fondo"
          width="100%"
          height="100%"
          src={raceData.track.fixtureBackgroundImage}
        />
      </div>
      <Carousel
        infiniteLoop={true}
        interval={5000}
        showThumbs={false}
        showStatus={false}
        width={"auto"}
        onChange={(e) => setHorseinfo(e)}
        autoPlay
        stopOnHover={false}
        selectedItem={clickedHorse}
      >
        {horses.map((horse) => {
          return (
            <img
              alt={"horseMiniature"}
              style={{ width: "79%", marginTop: "5%", marginBottom: "3%" }}
              src={horse.coin.horseImage}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export default ImageSlider;
