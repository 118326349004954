export const HudStyles = {
  // Hud
    hud: {
      fontSize: 20,
      color: "white",
      zIndex: 100,
      width: "100%",
      height: "33.89%",
      maxHeight: "33.89%",
      position: "absolute",
      backgroundColor: "rgb(0, 0, 0,0.25)",
    },

    hudContainer: {
      display: "grid",
      flexDirection: "row",
      gridTemplateColumns: '10% 70% 20%',
      gridTemplateRows: '100%',
      height: "100%",
      maxHeight: "100%",
    },

  // BallPositions
    ballPositionsContainer: {
      width: '100%',
      maxHeight: "100%",
      display: "grid",
      gridTemplateRows: "10% 90%",
    },

    racingFlag: {
      position: "absolute",
      height: "100%",
      width: "1.100vw",
      justifySelf: "right",
      backgroundImage:
        "url(https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/pattern_finish.png)",
    },

    distanceLineContainer: (left) => ({
      position: "absolute",
      height: "100%",
      width: "1px",
      borderLeft: "1px solid rgb(48, 48, 48, 0.4)",
      left: `${left}%`,
    }),

    distanceLine: {
      fontSize: "0.608vw",
      transform: "rotate(90deg)",
      marginLeft: "0.304vw",
      color: "rgb(48, 48, 48, 0.4)",
    },

    ballContainer: {
      maxHeight: "100%",
      display: "grid",
      gridTemplateColumns: "100%",
      gridTemplateRows: "repeat(12, 1fr)",
      alignItems: "center",
    },

    lane: {
      marginRight: "0.912vw",
    },

    horseBallContainer: (distance) => ({
      position: "relative",
      paddingRight: "0.912vw",
      left: `${distance}%`,
    }),
    horseBall: (primaryColor, secondaryColor) => ({
      backgroundColor: `#${primaryColor}`,
      border: `0.122vw solid #${secondaryColor}`,
      width: "0.866vmin",
      boxShadow: "-0.122vw 0px 3px #303030",
      height: "0.866vmin",
      borderRadius: "100%",
    }),
    horseTicker: (distance) => ({
      float: "left",
      marginLeft: "-3.652vw",
      fontSize: "0.608vw",
      textAlign: "right",
      width: "3.043vw",
    }),

};
