import * as THREE from "three";
import { add, rotateAroundPoint } from "../../../utils/three";
const textureFile =
  "https://cryptodromo-dev.s3.us-east-2.amazonaws.com/texturas/marquesina_sobre_gateras.png";
const backgroundTextureFile =
  "https://cryptodromo-dev.s3.us-east-2.amazonaws.com/texturas/Fondo_Negro_Largada.png";

export default ({
  parent,
  world,
  items = [],
  x = 0,
  y = 0,
  z = 0,
  radius = 4,
  height = 0.2,
  color = 0xdddddd,
  segments = 32,
  opacity = 1,
}) => {
  const geometry = new THREE.PlaneGeometry(1, 0.28);
  const material = new THREE.MeshBasicMaterial();

  const loader = new THREE.TextureLoader(window.manager);
  loader.load(textureFile, function (texture) {
    material.map = texture;
    material.depthWrite = true;
    material.transparent = true;
    material.needsUpdate = true;
  });
  const cylinder = new THREE.Mesh(geometry, material);

  cylinder.position.x = 0.1;
  cylinder.position.y = 0.32;
  cylinder.position.z = 0.555;
  cylinder.rotateY(90 * (Math.PI / 180));

  add(parent, cylinder);

  // const backgoundGeometry = new THREE.PlaneGeometry(1, 1);
  // const backgroundMaterial = new THREE.MeshBasicMaterial();

  // loader.load(backgroundTextureFile,
  //     function (texture) {
  //         backgroundMaterial.map = texture;
  //         backgroundMaterial.transparent = true;
  //         backgroundMaterial.depthWrite = false;
  //         backgroundMaterial.needsUpdate = true;
  //     });
  // const background = new THREE.Mesh(backgoundGeometry, backgroundMaterial);

  // background.position.x = -0.07;
  // background.position.y = 0;
  // background.position.z = 0.5;
  // background.rotateY(90*(Math.PI/180));

  // add(parent, background);

  return {
    model: cylinder,
  };
};
