import * as THREE from "three";
import Sprite from "../templateSprite/index";
import { promisifyLoader } from "../../../utils/three";

export default async ({ parent, world, x = 0, y = 0, z = 0 }) => {
  const loader = promisifyLoader(new THREE.TextureLoader(window.manager));
  const spriteSheet = loader.load(
    "https://cryptodromo-dev.s3.us-east-2.amazonaws.com/sprites/spritesheet_countdown.png"
  );

  const sprite = await Sprite({
    parent,
    x,
    y,
    z,
    spriteSheet,
    columns: 1,
    rows: 5,
    actions: {
      five: {
        start: { row: 0, column: 0 },
        scaleX: 0.1,
        scaleY: 0.1,
        speed: 0.0,
      },
      four: {
        start: { row: 1, column: 0 },
        end: { row: 5, column: 0 },
        scaleX: 0.1,
        scaleY: 0.1,
        speed: 0.0,
      },
      three: {
        start: { row: 2, column: 0 },
        end: { row: 5, column: 0 },
        scaleX: 0.1,
        scaleY: 0.1,
        speed: 0.0,
      },
      two: {
        start: { row: 3, column: 0 },
        end: { row: 5, column: 0 },
        scaleX: 0.1,
        scaleY: 0.1,
        speed: 0.0,
      },
      one: {
        start: { row: 4, column: 0 },
        end: { row: 5, column: 0 },
        scaleX: 0.1,
        scaleY: 0.1,
        speed: 0.0,
      },
      disapear: {
        start: { row: 5, column: 0 },
        end: { row: 5, column: 0 },
        scaleX: 0.1,
        scaleY: 0.1,
        speed: 0.0,
      },
    },
  });
  sprite.scaleX = 0.1;
  sprite.scaleY = 0.1;
  sprite.actions.disapear();
  sprite.timelines.controls = {
    while: true,
    update(self, entities, { directions }, { gamepadController, time }) {
      if (time.current < -5000) {
        return self.actions.disapear();
      }
      if (time.current < -4000) {
        return self.actions.five();
      }
      if (time.current < -3000) {
        return self.actions.four();
      }
      if (time.current < -2000) {
        return self.actions.three();
      }
      if (time.current < -1000) {
        return self.actions.two();
      }
      if (time.current < 0) {
        return self.actions.one();
      }
      if (time.current > 0) {
        self.actions.disapear();
      }
    },
  };
  return sprite;
};
