import { useEffect } from "react";
import React from "react";
import { styles } from "./styles";
import lodash from "lodash";
import { orderBy } from "lodash";
import GateNumberBox from "../GateBox";

const Results = (props) => {
  const { raceData, time, horseObjs, fontSize } = props;
  const horseObjects = lodash.sortBy(raceData.participants, "gate");
  return (
    <div style={styles.resultsContainer(fontSize)}>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>200m</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              style={styles.resultsColumnResult(
                obj.firstSplit * 1000 < time.current,
                raceData.firstSplitRecord === obj.id
              )}
            >
              <span>
                {obj.firstSplit * 1000 < time.current
                  ? obj.firstSplit
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>400m</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              style={styles.resultsColumnResult(
                obj.secondSplit * 1000 < time.current,
                raceData.secondSplitRecord === obj.id
              )}
            >
              <span>
                {obj.secondSplit * 1000 < time.current
                  ? obj.secondSplit
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>600m</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              style={styles.resultsColumnResult(
                obj.thirdSplit * 1000 < time.current,
                raceData.thirdSplitRecord === obj.id
              )}
            >
              <span>
                {obj.thirdSplit && obj.thirdSplit * 1000 < time.current
                  ? obj.thirdSplit
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>800m</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              style={styles.resultsColumnResult(
                obj.fourthSplit * 1000 < time.current,
                raceData.fourthSplitRecord === obj.id
              )}
            >
              <span>
                {obj.fourthSplit && obj.fourthSplit * 1000 < time.current
                  ? obj.fourthSplit
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>TOTAL</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              style={styles.resultsColumnResult(
                obj.finalTime * 1000 < time.current,
                raceData.finalTimeRecord === obj.id
              )}
            >
              <span>
                {obj.finalTime && obj.finalTime * 1000 < time.current
                  ? obj.finalTime
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Results;

export const FinalResults = (props) => {
  const { raceData, time, horseObjs, fontSize } = props;
  const horseObjects = orderBy(raceData.participants, (o) => o.finalTime, [
    "ASC",
  ]);
  const FirstPositions = horseObjects[0];

  return (
    <div style={styles.finalResultsContainer(fontSize)}>
      <div>
        <div style={styles.headboard}>
          <p>POS</p>
        </div>
        <div style={styles.contentContainer}>
          <div style={styles.content}>
            {horseObjects &&
              horseObjects.map((obj, index) => <p>{index + 1}</p>)}
          </div>
        </div>
      </div>
      <div>
        <div style={styles.headboard}>
          <p>RACER</p>
        </div>
        <div style={styles.contentContainer}>
          <div style={styles.content}>
            {horseObjects &&
              horseObjects.map((obj, index) => (
                <div style={styles.horseContainer} key={index}>
                  <img
                    alt={"horseMiniature"}
                    style={styles.horseImg}
                    src={obj.coin.horseImage}
                  />
                  <p style={styles.horseName}>
                    {obj.coin.symbol.split("USDT")[0]}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div>
        <div style={styles.headboard}>
          <p>GATE</p>
        </div>
        <div style={styles.contentContainer}>
          <div style={styles.content}>
            {horseObjects.map((obj, index) => (
              <GateNumberBox
                key={index}
                gate={obj.gate}
                width="26px"
                height="26px"
                fontWeight="700"
                gateNumberStyle={obj.gate - 1}
              />
            ))}
          </div>
        </div>
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>200m</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              key={index}
              style={styles.resultsColumnResult(
                obj.firstSplit * 1000 < time.current,
                raceData.firstSplitRecord === obj.id
              )}
            >
              <span>
                {obj.firstSplit * 1000 < time.current
                  ? obj.firstSplit
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>400m</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              key={index}
              style={styles.resultsColumnResult(
                obj.secondSplit * 1000 < time.current,
                raceData.secondSplitRecord === obj.id
              )}
            >
              <span>
                {obj.secondSplit * 1000 < time.current
                  ? obj.secondSplit
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>600m</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              key={index}
              style={styles.resultsColumnResult(
                obj.thirdSplit * 1000 < time.current,
                raceData.thirdSplitRecord === obj.id
              )}
            >
              <span>
                {obj.thirdSplit * 1000 < time.current
                  ? obj.thirdSplit
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>800m</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              key={index}
              style={styles.resultsColumnResult(
                obj.fourthSplit * 1000 < time.current,
                raceData.fourthSplitRecord === obj.id
              )}
            >
              <span>
                {obj.fourthSplit && obj.fourthSplit * 1000 < time.current
                  ? obj.fourthSplit
                  : "--.---"}
              </span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>TOTAL</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              key={index}
              style={styles.resultsColumnResult(
                obj.finalTime * 1000 < time.current,
                raceData.finalTimeRecord === obj.id
              )}
            >
              {obj.finalTime && obj.finalTime}
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>GAP</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div
              key={index}
              style={styles.resultsColumnResult(
                obj.finalTime * 1000 < time.current,
                raceData.finalTimeRecord === obj.id
              )}
            >
              {index === 0 ? (
                <span></span>
              ) : (
                <span>
                  {"+" + (obj.finalTime - FirstPositions.finalTime).toFixed(3)}
                </span>
              )}
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>AVG SPEED</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div key={index} style={styles.resultsColumnResult()}>
              {obj.averageSpeed} km/h
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>POINTS</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div key={index} style={styles.resultsColumnResult()}>
              <span>{obj.points}</span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div style={styles.resultsColumn}>
        <div style={styles.resultsColumnTitle}>ODDS</div>
        {horseObjects ? (
          horseObjects.map((obj, index) => (
            <div key={index} style={styles.resultsColumnResult()}>
              <span>{obj.ratio}</span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
