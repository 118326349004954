import React from "react";

/**
 * Button with the text bet to redirect to the bet page
 * @param {onClick} onClick onclick event, receives an ammo
 * @param {width} width modify button sizemodify button size
 * @param {fontSize} fontSize modify font theme
 * @param {disabled} disabled state of button
 * @param {type} type is the type of the button, especially for forms
 * @returns
 */

const BetButton = ({
  onClick,
  width,
  height,
  fontSize,
  fontWeight,
  disabled,
  text,
  style,
  type,
}) => (
  <button
    style={{
      width: width || "40%",
      height: height,
      background: "#3bb34f",
      color: "white",
      border: "solid transparent 1px",
      borderRadius: "50px",
      cursor: "pointer",
      fontSize: fontSize || "1.000vw",
      fontWeight: fontWeight || "500",
      ...(disabled && {
        backgroundColor: "#4a4a4a",
        color: "#787878",
        cursor: "not-allowed",
      }),
      ...style,
    }}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    {text || "BET NOW"}
  </button>
);

export default BetButton;
