import * as THREE from "three";
import { cloneTexture, add } from "../../../utils/three";
import { remap, clamp } from "../../../utils";

const speedConverter = function (speed) {
  if (speed < 12) {
    return 0.37;
  }
  if (speed < 24) {
    return 0.38;
  }
  if (speed < 36) {
    return 0.39;
  }
  if (speed < 48) {
    return 0.4;
  }
  if (speed < 52) {
    return 0.42;
  }
  if (speed < 56) {
    return 0.44;
  }
  if (speed < 60) {
    return 0.46;
  }
  if (speed < 64) {
    return 0.48;
  }
  if (speed < 68) {
    return 0.5;
  }
  if (speed < 72) {
    return 0.52;
  }
  return 0.52;
};

const findDose = (time, doses) => {
  return (
    doses.find((dose) => dose.timeStart < time && dose.timeEnd > time) ||
    doses[doses.length - 1]
  );
};

export default async ({
  parent,
  x = 0,
  z = 0,
  y = 0,
  spriteSheet,
  rows,
  columns,
  actions: mappings = {},
}) => {
  const texture = cloneTexture(await Promise.resolve(spriteSheet));

  texture.needsUpdate = true;
  texture.repeat.set(1 / columns, 1 / rows);

  const geometry = new THREE.PlaneGeometry(1, 1);
  const material = new THREE.MeshBasicMaterial();

  material.map = texture;
  material.depthWrite = false;
  material.transparent = true;
  material.needsUpdate = true;
  const sprite = new THREE.Mesh(geometry, material);

  // const spriteMaterial = new THREE.SpriteMaterial({ map: texture, color: 0xffffff, transparent: true });
  // const sprite = new THREE.Sprite(spriteMaterial);

  sprite.position.x = x;
  sprite.position.y = y;
  sprite.position.z = z;

  // add(parent, sprite);

  const actions = {};
  const timelines = {};

  Object.keys(mappings).forEach((key) => {
    actions[key] = () => {
      if (timelines.action && timelines.action.key === key) return;

      let {
        start,
        end,
        loop = true,
        speed = 0.25,
        update,
        scaleX = 0.4,
        scaleY = 0.4,
        flipX = false,
        flipY = false,
      } = mappings[key];
      end = end || start;
      sprite.width = 1;
      sprite.height = 1;
      sprite.scale.x = scaleX;
      sprite.scale.y = scaleY;

      texture.repeat.x = Math.abs(texture.repeat.x) * (flipX ? -1 : 1);
      texture.repeat.y = Math.abs(texture.repeat.y) * (flipY ? -1 : 1);

      let startColumn = start.column;
      let startRow = start.row;
      let endColumn = end.column;
      let endRow = end.row;

      if (flipX) {
        startColumn++;
        endColumn++;
      }

      if (flipY) {
        startRow++;
        endRow++;
      }

      if (loop) {
        endColumn++;
        endRow++;
      }

      timelines.action = {
        while: true,
        counter: 0,
        key,
        update(entity, entities, timeline, args) {
          const horsesData = window.raceData.participants;
          const { time } = args;
          if (time.current < 0) {
            return;
          } else {
            const thisEntity = entity;
            const thisEntityDoses = horsesData.find(
              (item) => item.id === thisEntity.data.id
            ).stats;
            try {
              const dose = findDose(time.current, thisEntityDoses);
              if (time.current < 5000) {
                const partialSpeed =
                  dose.speedEnd * Math.pow(time.current / 5000, 2);
                speed = speedConverter(partialSpeed);
              } else {
                const currentDose = Math.floor(time.current / 5000);
                const doseStartSpeed = dose.speedStart;
                const partialSpeed =
                  dose.slope * (time.current - currentDose * 5000) +
                  doseStartSpeed;
                speed = speedConverter(partialSpeed);
              }
            } catch (error) {
              window.location.reload();
            }
          }

          const increment =
            (speed * 1) /
            Math.max(
              Math.abs(endColumn - startColumn),
              Math.abs(endRow - startRow),
              1
            );
          const percentage = loop
            ? timeline.counter % 1
            : clamp(timeline.counter, 0, 1);
          const column = Math.trunc(
            remap(percentage, 0, 1, startColumn, endColumn)
          );
          const row = Math.trunc(remap(percentage, 0, 1, startRow, endRow));
          texture.offset.x = column / columns;
          texture.offset.y = row / rows;
          timeline.counter += increment;

          if (update) update(entity, entities, { column, row }, args);
        },
      };
    };
  });

  return {
    model: sprite,
    actions,
    timelines,
  };
};
