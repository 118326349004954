import Camera from "./camera";
import Timeline from "./timeline";
import HUD from "./hud";
import GamepadController from "./gamepad-controller";
import KeyboardController from "./keyboard-controller";
import MouseController from "./mouse-controller";
import Race from "./race";

export default [
	GamepadController(),
	KeyboardController(),
	MouseController(),
	Camera({ pitchSpeed: -0.02, yawSpeed: 0.02 }),
	Timeline,
	Race,
	HUD
];
