export default class CustomTimer {
    countdown = 5000
    // countdown = -104000
    currentTime = 0
    constructor() {
        this.subscribers = [];
        const that = this;
        this.loopId = null;
        this.startTime = null
    }

    setStartingTime(time) {
        this.countdown = time
    }

    loop = time => {
        if (time && this.startTime === null) {
            this.startTime = time
        }

        if (this.loopId) {
            this.subscribers.forEach(callback => {
                this.currentTime = time - this.startTime - this.countdown
                callback(time  - this.startTime - this.countdown);
                // callback(100000);
            });
        }
        this.loopId = requestAnimationFrame(this.loop);
    };

    getCurrentTime() {
        return this.currentTime
    }

    start() {
        if (!this.loopId) {
            this.loop();
        }
    }

    stop() {
        if (this.loopId) {
            cancelAnimationFrame(this.loopId);
            this.loopId = null;
            this.startTime = null
        }
    }

    subscribe(callback) {
        if (this.subscribers.indexOf(callback) === -1)
            this.subscribers.push(callback);
    }

    unsubscribe(callback) {
        this.subscribers = this.subscribers.filter(s => s !== callback)
    }
}