import * as THREE from "three";
import Camera from "./components/sections/camera/index";
import HorseAda from "./components/sections/horseAda/index";
import HUD from "./components/sections/hud/index";
import MenuLayout from "./components/sections/menuLayout";
import StartStats from "./components/sections/startStats/index";
import FinalStats from "./components/sections/finalStats/index";
import { clear } from "./utils/three";
import { add } from "./utils/three";
import background from "./components/sections/background/background";
import barandaPosterior from "./components/sections/background/baranda_izq.jsx";
import gatera from "./components/sections/background/gatera.jsx";
import floor from "./components/sections/background/floor.jsx";
import baranda_anterior from "./components/sections/background/baranda_anterior";
import startGatera from "./components/sections/background/startGatera.jsx";
import skyTribuna from "./components/sections/background/skyTribuna.jsx";
import CountdownSprite from "./components/sections/countDownSprite/index";
import bushes from "./components/sections/background/bushes.jsx";
import lodash from "lodash";
const findDose = (time, doses) => {
  return (
    doses.find((dose) => dose.timeStart < time && dose.timeEnd >= time) ||
    doses[0]
  );
};

async function processHorses(horses) {
  const horsesArray = await Promise.all(
    horses.map(async (horse) => {
      return await HorseAda({
        doses: horse.stats,
        image: horse.coin.horseSpritesheet,
        name: horse.coin.symbol,
        data: horse,
      });
    })
  );
  return horsesArray;
}
export default async (raceData, opts) => {
  const { tribuna, startTime } = opts;
  const scene = new THREE.Scene();
  const positions = [];
  let cameraPosition = 0.69;

  if (startTime > 5000) {
    raceData.participants.forEach((horseData) => {
      const currDose = findDose(startTime, horseData.stats);
      const position = currDose.positionEnd;

      positions.push(position);
    });
    cameraPosition = lodash.max(positions) * 60;
    if (cameraPosition > 60) {
      cameraPosition = 60;
    }
  }
  clear(scene);

  const camera = Camera(cameraPosition);

  scene.fog = new THREE.FogExp2(0x000000, 0);
  camera.position.set(cameraPosition, 0.5, 1.7);
  scene.fog.near = 0.1;
  scene.fog.far = 0;
  camera.lookAt(new THREE.Vector3(cameraPosition, 0, 0.25));
  const horseObjects = lodash.sortBy(raceData.participants, "gate");
  const horses = await processHorses(horseObjects);
  const countdownSprite = await CountdownSprite({ x: 0.7, y: 0, z: 0.5 });
  add(scene, countdownSprite);
  const track = raceData.track;

  await background({ parent: scene, track });
  await barandaPosterior({ parent: scene, track });
  await baranda_anterior({ parent: scene, track });
  await startGatera({ parent: scene, track });
  await floor({ parent: scene, track });
  await bushes({ parent: scene, track });

  if (tribuna) {
    await skyTribuna({ parent: scene, x: 12, number: 1, width: 4, track });
    await skyTribuna({ parent: scene, x: 24, number: 2, width: 4, track });
    await skyTribuna({ parent: scene, x: 36, number: 3, width: 4, track });
    await skyTribuna({ parent: scene, x: 48, number: 4, width: 4, track });
    await skyTribuna({ parent: scene, x: 60, number: 5, width: 4, track });
  }

  // load a sound and set it as the Audio object's buffer
  const audioLoader = new THREE.AudioLoader();

  window.sound = () => {};
  window.soundOff = () => {};
  const gateraElement = gatera({ parent: scene, z: 0.062, y: 0.06 });
  add(scene, gateraElement);

  horses.forEach((item, idx) => {
    const gateraElement = gatera({
      parent: scene,
      z: 0.062 + (1 + idx) * 0.075,
      y: 0.06,
    });
    add(scene, gateraElement);
  });

  horses.forEach((item, idx) => {
    item.model.position.z = 0.0995 + idx * 0.075;
    item.model.position.y = 0.06;
    item.model.position.x = -0.035;
    add(scene, item);
  });

  const hud = HUD();
  const menuLayout = new MenuLayout();
  const startStats = StartStats();
  const finalStats = FinalStats();

  const entities = {
    scene,
    camera,
    startStats,
    finalStats,
    countdownSprite,
    hud,
    menuLayout,
    ...horses.reduce((a, v) => ({ ...a, [v.name]: v }), {}),
  };

  return entities;
};
