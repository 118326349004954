import * as THREE from "three";
import { add, cloneTexture, promisifyLoader } from "../../../utils/three";
const loader = promisifyLoader(new THREE.TextureLoader(window.manager));

export default async ({
  parent,
  x = 30,
  y = 0.4,
  z = 0,
  width = 2,
  number = 1,
  track,
}) => {
  const {
    grandstandOne,
    grandstandTwo,
    grandstandThree,
    grandstandFour,
    grandstandFive,
  } = track;

  const geometry = new THREE.PlaneGeometry(width, width / 4);
  const material = new THREE.MeshBasicMaterial();

  let textureFile;
  switch (number) {
    case 1:
      textureFile = loader.load(grandstandOne);
      break;
    case 2:
      textureFile = loader.load(grandstandTwo);
      break;
    case 3:
      textureFile = loader.load(grandstandThree);
      break;
    case 4:
      textureFile = loader.load(grandstandFour);
      break;
    case 5:
      textureFile = loader.load(grandstandFive);
      break;
    default:
      break;
  }

  const texture = cloneTexture(await Promise.resolve(textureFile));

  material.map = texture;
  material.depthWrite = false;
  material.transparent = true;
  material.needsUpdate = true;
  const cylinder = new THREE.Mesh(geometry, material);

  cylinder.position.x = x;
  cylinder.position.y = y;
  cylinder.position.z = -0.8;
  cylinder.rotateX(-5 * (Math.PI / 180));

  add(parent, cylinder);

  return {
    model: cylinder,
  };
};
