import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorBoundary from "./game/components/common/errorBoundary/index.jsx";
import Game from "./game/index.jsx";
import RacesList from "./races.jsx";
import Reprocess from "./reprocess.jsx";

const GameRoutes = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Game />}></Route>
          <Route path="/races" element={<RacesList />}></Route>
          <Route path="/reprocess" element={<Reprocess />}></Route>
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default GameRoutes;
