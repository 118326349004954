export const styles = {
  hud: {
    fontSize: 20,
    color: "white",
    zIndex: 100,
    width: "90%",
    padding: "55px 50px 15px 50px",
    height: "90%",
    position: "absolute",
    left: "5%",
    top: "2.5%",
    boxSizing: "border-box",
    backgroundColor: "rgb(0, 0, 0,0.95)",
    display: "grid",
    gridTemplateRows: "9% 76% 15%",
    borderRadius: "18px",
    alignContent: "space-between",
  },
  nextRaceTittle: {
    lineHeight: 1,
    fontSize: "1.900vw",
    fontWeight: "700",
    marginLeft:"0.5%"
  },

  //next race stats
  nextRaceContent: {
    display: "grid",
    gridTemplateColumns: "16% 65% 19%",
    gridTemplateRows: "100%",
    justifyItems: "end",
  },
  nextRaceSlider: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  horseStats: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "26% 74%",
    fontSize: "2.200vw",
    fontWeight: "700",
  },
  horseStatsTittle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "end",
  },
  StatsCoin: {
    display: "flex",
    alignItems: "center",
    gap: "2%",
    fontSize: "0.50em",
  },
  horseStatsInfo: {
    display: "flex",
    flexDirection: "column",
    fontSize: "0.50em",
    gap: "10%",
  },
  horseInfoTittle: {
    fontWeight: "700",
    color: "grey",
    lineHeight: "1.5",
  },
  horseInfoContet: {
    fontWeight: "600",
    textAlign: "end",
    lineHeight: "1.5",
  },
  horseInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    gap: "0.3rem",
  },
  horseInfoRakingContent: {
    display: "flex",
    gap: "4px",
    color: "white",
    alignItems: "center",
  },

  lastDayPerformaceContent: {
    fontWeight: "500",
    lineHeight: "2.5",
    color: "white",
    display: "grid",
    gridTemplateColumns: "15% 60% 25%",
    alignItems: "center",
    alignContent: "center",
    justifyItems: "end",
    flexDirection: "row",
    width: "100%",
  },
  horseBall: (primaryColor, secondaryColor) => ({
    backgroundColor: `#${primaryColor}`,
    border: `0.290vw solid #${secondaryColor}`,
    width: "3.50vmin",
    height: "3.50vmin",
    borderRadius: "100%",
  }),

  //next race grid
  nextRaceGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "20px",
    paddingBottom: "10px",
    fontSize: "1.35vw",
  },

  gridContent: {
    textAlign: "-webkit-center",
    backgroundColor: "#0e0d0d",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10%",
    borderRadius: "10px",
    color: "white",
  },

  gridHorseContainer: {
    display: "grid",
    gridTemplateColumns:"27% 40% 33%",
    borderRadius: "initial",
    alignItems: "center",
    justifyContent: "center",
    height: "40%",
    width:"full",
    margin: "0 5%",
    justifyItems:"center"
  },

  gridHorse: { margin: "1", display: "flex", justifyContent: "center" },

  gridHorseName: {
    marginLeft: "1",
    fontSize: "1.01em",
    fontWeight: "500",
    verticalAlign: "middle",
    lineHeight: "60px",
  },

  gridGate: {
    display: "flex",
    width: "13%",
    verticalAlign: "middle",
    fontSize: "0.608vw",
    fontWeight: "600",
  },

  gridRatio: {
    height: "30.289%",
    backgroundColor: "#221f1e",
    margin: "0 5%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  // Horse Info

  horseInfoContainer: {
    display: "grid",
    gridTemplateRows: "25% 75%",
    gridTemplateColumns: "100%",
    fontSize: "2.130vw",
    justifyContent: "center",
    paddingRight: "10px",
  },
  horseInfoHead: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  horseInfoSymbol: {
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    color: "white",
  },
  horseInfoBall: (primaryColor, secondaryColor) => ({
    width: "2.80vmin",
    height: "2.80vmin",
    backgroundColor: `#${primaryColor}`,
    border: `0.26vw solid #${secondaryColor}`,
    borderRadius: "100%",
    marginLeft: "10px",
  }),

  horseInfoName: {
    textAlign: "end",
    color: "grey",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "0.55em",
  },

  // footer
  nextRaceFooter: {
    display: "grid",
    gridTemplateColumns: "79% 21%",
    alignItems: "center",
    justifyItems: "end",
  },
  specialRace: {
    display: "grid",
    gridTemplateColumns: "50% repeat(3, 1fr)",
    alignItems: "center",
    justifyItems: "end",
  },
  nexRaceCooldown: {
    color: "white",
    fontWeight: "700",
    letterSpacing: "0.05rem",
    fontSize: "1.900vw",
  },
};
