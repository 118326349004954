import React from "react";

const numberPositionsStyles = [
  { background: "#972a19", color: "white", aspectRatio: "1/1" },
  { background: "white", color: "black", aspectRatio: "1/1" },
  { background: "#245693", color: "white", aspectRatio: "1/1" },
  { background: "#fadd07", color: "black", aspectRatio: "1/1" },
  { background: "#2e9028", color: "white", aspectRatio: "1/1" },
  { background: "#383838", color: "#fdad25", aspectRatio: "1/1" },
  { background: "#b86a01", color: "black", aspectRatio: "1/1" },
  { background: "#f57bf8", color: "black", aspectRatio: "1/1" },
  { background: "#18629f", color: "black", aspectRatio: "1/1" },
  { background: "#4e1584", color: "white", aspectRatio: "1/1" },
  { background: "#5b5b5b", color: "white", aspectRatio: "1/1" },
  { background: "#7d9b15", color: "black", aspectRatio: "1/1" },
];

const getNumberStyle = (style) => numberPositionsStyles[style];

const GateNumberBox = ({
  gate,
  gateNumberStyle,
  width,
  height,
  fontSize,
  fontWeight,
  margin,
  onClick,
}) => (
  <p
    style={{
      ...getNumberStyle(gateNumberStyle),
      width: width || "23px",
      height: height || "23px",
      fontSize: fontSize,
      fontWeight: fontWeight,
      margin: margin,
      borderRadius: "3px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    onClick={onClick}
  >
    {gate}
  </p>
);

export default GateNumberBox;
