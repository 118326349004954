import React from "react";
import { HudStyles } from "../styles";
import lodash from "lodash";


const BallPositions = (props) => {
  const { horsePositions, horseObjs, raceData } = props;
  const horseObjects = lodash.sortBy(horsePositions, "gate");

  return (
    <div style={HudStyles.ballPositionsContainer}>
      <div></div>
      <div style={HudStyles.racingFlag}></div>
      <div style={HudStyles.distanceLineContainer(24)}>
        <div style={HudStyles.distanceLine}>200</div>
      </div>
      <div style={HudStyles.distanceLineContainer(38)}>
        <div style={HudStyles.distanceLine}>400</div>
      </div>
      <div style={HudStyles.distanceLineContainer(52)}>
        <div style={HudStyles.distanceLine}>600</div>
      </div>
      <div style={HudStyles.distanceLineContainer(66)}>
        <div style={{ ...HudStyles.distanceLine}}>800</div>
      </div>
      <div style={HudStyles.ballContainer}>
        {horseObjects ? (
          horseObjects.map((horse, index) => {
            return (
              <div style={HudStyles.lane}>
                <div
                  style={HudStyles.horseBallContainer(
                    horse.position > 60 ? 100 : (100 * horse.position) / 60
                  )}
                >
                  {horse.position > 3 && (
                    <div
                      style={HudStyles.horseTicker(
                        horse.position > 60 ? 100 : (100 * horse.position) / 60
                      )}
                    >
                      {horse.data.coin.symbol.split("USDT")[0]}
                    </div>
                  )}
                  <div
                    style={HudStyles.horseBall(
                      horse.data.coin.primaryColor,
                      horse.data.coin.secondaryColor
                    )}
                  ></div>
                </div>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default BallPositions;
