import React, { useContext, useEffect } from "react";
import HorsesPositions from "../../common/horsesPositions";
import { HudStyles } from "./styles";
import BallPositions from "./components/BallPositions";
import Results from "../../common/raceResults";
import { RaceDataContext } from "../../../contexts/raceData";
import lodash from "lodash";
const HUDRenderer = (props) => {
  const { camera, time, horses, entities } = props;
  const horsePositions = [];
  const raceData = useContext(RaceDataContext);

  useEffect(() => {}, [raceData]);
  const horseObjs = [];

  if (horses) {
    const horseObjects = lodash.sortBy(raceData.participants, "gate");

    horses.forEach((entity, key) => {
      horsePositions.push({
        position: entities[entity].model.position.x,
        gate: entities[entity].data.gate,
        data: entities[entity].data,
      });
      horseObjs.push(entities[entity]);
    });
  }
  if (!horseObjs.length) {
    return null;
  }

  if (time.current < -5000) {
    return null;
  }
  if (
    horsePositions.find((x) => x.position < 61) === undefined ||
    time.current > 75000
  ) {
    return null;
  }

  return (
    <div style={HudStyles.hud}>
      <div style={HudStyles.hudContainer}>
        <HorsesPositions horseObjs={horseObjs} />
        <BallPositions
          horsePositions={horsePositions}
          raceData={raceData}
          horseObjs={horseObjs}
        />
        <Results raceData={raceData} horseObjs={horseObjs} time={time} />
      </div>
    </div>
  );
};

export default (raceData) => {
  return { renderer: <HUDRenderer raceData={raceData} /> };
};

// Verde de ganador split #7fff8e
// Violeta ganador de record split #f67fff
