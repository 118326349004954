export const styles = {
  horseMiniatureColumn: (showTittle, background, fontSize) => ({
    backgroundColor: background ? "rgba(0, 0, 0, 0.4)" : "",
    textAlign: "center",
    display: "grid",
    gridTemplateRows: showTittle ? "10% 90%" : "100%",
    width: "100%",
    fontSize: fontSize ? fontSize : "0.608vw",
  }),

  positionsTittle: {
    fontSize: "1.2em",
    fontWeight: "600",
    color: "grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  horsePositionsContainer: (horseObjsNextRace) => ({
    display: horseObjsNextRace ? "" : "flex",
    flexDirection: "row",
    justifyContent: "center",
  }),

  horseMiniatureContainer: (horseObjsNextRace) => ({
    display: "grid",
    gridTemplateColumns: horseObjsNextRace ? "100%" : "50%",
    gridTemplateRows: "repeat(12, 8.3%)",
    alignItems: "center",
    height: "100%",
  }),

  numberPositions: (placeNumbers) => ({
    width: "2.130vw",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "repeat(12, 8.3%)",
    placeItems: placeNumbers ? placeNumbers : "center end",
    fontSize: "1.2em",
    fontWeight: "600",
    color: "rgb(255,255,255,0.5)",
  }),

  miniatures: (horseWidth) => ({
    width: horseWidth ? horseWidth : "1.217vw",
    display: "inline",
  }),

  horseName: {
    textAlign: "left",
    fontWeight: "600",
    width: "2.130vw",
  },

  horseMiniatures: {
    width: "6.083vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  horseMiniaturesNextRace: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "100%",
    textAlign: "left",
    alignItems: "center",
    height: "100%",
    fontWeight: "600",
    color: "white",
  },
  horseNameNextRace: {
    textAlign: "left",
    fontWeight: "600",
    width: "2.130vw",
    cursor: "pointer",
  },
  miniaturesNextRace: (horseWidth) => ({
    width: horseWidth ? horseWidth : "1.217vw",
    display: "inline",
    cursor: "pointer",
  }),

  horseContainer: {
    display: "grid",
    gridTemplateRows: "repeat(12, 8.3%)",
    alignItems: "center",
    justifyItems: "center",
    fontSize: "0.608vw",
    fontSize: "0.852vw",
  },
  horses: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "25% 35% 40%",
    gridTemplateRows: "100%",
    textAlign: "left",
    alignItems: "center",
    fontWeight: "600",
    color: "white",
  },
  horseSymbol: {
    textAlign: "left",
    fontWeight: "600",
    cursor: "pointer",
    marginLeft: "10%",
  },
};
