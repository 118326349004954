export const styles = {
  resultsContainer: (fontSize) => ({
    fontSize: fontSize ? fontSize : "0.730vw",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
  }),
  finalResultsContainer: (fontSize) => ({
    fontSize: fontSize ? fontSize : "0.730vw",
    width: "100%",
    display: "grid",
    gridTemplateColumns: ".6fr 1.1fr .6fr repeat(6, 1fr) 1.2fr .8fr .7fr",
    gridTemplateRows: "100%",
    textAlign: "center",
  }),
  resultsColumn: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "10% repeat(12, 7.5%)",
    alignItems: "center",
  },
  resultsColumnTitle: {
    margin: "auto",
    fontWeight: "bold",
    color: "#878787",
  },
  resultsColumnResult: (shouldDisplay, isRecord) => ({
    textAlign: "center",
    color: shouldDisplay && isRecord ? "#7fff8e" : "white",
    fontWeight: shouldDisplay && isRecord ? "bolder" : "normal",
  }),
  headboard: {
    height: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: "#878787",
  },
  contentContainer: {
    height: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  content: {
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "repeat(12, 8.3%)",
    placeItems: "center",
    color: "white",
    fontWeight: "700",
  },
  horseContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  horseImg: {
    display: "inline",
    width: "50%",
  },
  horseName: {
    textAlign: "left",
    fontWeight: "600",
    color: "white",
    fontSize: "1.05rem",
    width: "40%",
  },
};
