import React from "react";
import { styles } from "../styles";
import { HudStyles } from "../../hud/styles";
import GateNumberBox from "../../../common/GateBox";
const HorsesGrid = (props) => {
  const { horseObjs } = props;
  return (
    <div style={styles.nextRaceGrid}>
      {horseObjs.map((obj, index) => (
        <div style={styles.gridContent} key={index}>
          <div style={styles.gridHorseContainer}>
            <div style={styles.gridGate}>
              <GateNumberBox
                gate={obj.gate}
                gateNumberStyle={obj.gate - 1}
                fontSize="1.300vw"
                fontWeight="700"
                margin="auto"
                width={"1.800vw"}
                height={"1.800vw"}
              />
            </div>
            <img src={obj.coin.horseImage} width={"80%"} style={{marginLeft:"10px"}}/>
            <span style={styles.gridHorseName}>
              {obj.coin.symbol.replace("USDT", "")}
            </span>
          </div>
          <div style={styles.gridRatio}>
            <span>{obj.ratio || "Calculating"}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorsesGrid;
