import * as THREE from "three";
import { add } from "../../../utils/three";

export default ({ parent, track }) => {
  const { leftFenceMiddleImage, leftFenceStartImage, leftFenceFinishImage } =
    track;
  const loader = new THREE.TextureLoader(window.manager);
  const zlevel = -0.05;

  // Beginning
  const beginningGeometry = new THREE.PlaneGeometry(2, 0.56 * 1.1);
  const beginningMaterial = new THREE.MeshBasicMaterial();

  loader.load(leftFenceMiddleImage, function (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(1, 1);
    beginningMaterial.map = texture;
    beginningMaterial.depthWrite = false;
    beginningMaterial.transparent = true;
    beginningMaterial.needsUpdate = true;
  });
  const beginningMesh = new THREE.Mesh(beginningGeometry, beginningMaterial);

  beginningMesh.position.x = -2;
  beginningMesh.position.y = 0.15;
  beginningMesh.position.z = zlevel;

  add(parent, beginningMesh);

  // Start
  const startGeometry = new THREE.PlaneGeometry(2, 0.56 * 1.1);
  const startMaterial = new THREE.MeshBasicMaterial();

  loader.load(leftFenceStartImage, function (texture) {
    startMaterial.map = texture;
    startMaterial.depthWrite = false;
    startMaterial.transparent = true;
    startMaterial.needsUpdate = true;
  });
  const startMesh = new THREE.Mesh(startGeometry, startMaterial);

  startMesh.position.x = 0;
  startMesh.position.y = 0.15;
  startMesh.position.z = zlevel;
  add(parent, startMesh);

  // Middle

  const geometry = new THREE.PlaneGeometry(58, 0.56 * 1.1);
  const material = new THREE.MeshBasicMaterial();

  loader.load(leftFenceMiddleImage, function (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(29, 1);
    material.map = texture;
    material.depthWrite = false;
    material.transparent = true;
    material.needsUpdate = true;
  });
  const cylinder = new THREE.Mesh(geometry, material);

  cylinder.position.x = 30;
  // cylinder.position.y = 0.075;
  cylinder.position.y = 0.15;
  cylinder.position.z = zlevel;

  add(parent, cylinder);

  // Finish
  const finishGeometry = new THREE.PlaneGeometry(2, 0.56 * 1.1);
  const finishMaterial = new THREE.MeshBasicMaterial();

  loader.load(leftFenceFinishImage, function (texture) {
    finishMaterial.map = texture;
    finishMaterial.depthWrite = false;
    finishMaterial.transparent = true;
    finishMaterial.needsUpdate = true;
  });
  const finishMesh = new THREE.Mesh(finishGeometry, finishMaterial);

  finishMesh.position.x = 60;
  finishMesh.position.y = 0.15;
  finishMesh.position.z = zlevel;

  add(parent, finishMesh);

  // After finish
  const afterFinishGeometry = new THREE.PlaneGeometry(2, 0.56 * 1.1);
  const afterFinishMaterial = new THREE.MeshBasicMaterial();

  loader.load(leftFenceMiddleImage, function (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(1, 1);
    afterFinishMaterial.map = texture;
    afterFinishMaterial.depthWrite = false;
    afterFinishMaterial.transparent = true;
    afterFinishMaterial.needsUpdate = true;
  });
  const afterFinishMesh = new THREE.Mesh(
    afterFinishGeometry,
    afterFinishMaterial
  );

  afterFinishMesh.position.x = 62;
  afterFinishMesh.position.y = 0.15;
  afterFinishMesh.position.z = zlevel;

  add(parent, afterFinishMesh);

  return {
    model: cylinder,
  };
};
