import React from "react";

const Reprocess = () => {
  return (
    <>
      <form>
        <label>Id de carrera</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
        <label>Coeficiente 1</label>
        <input></input>
      </form>
    </>
  );
};

export default Reprocess;
