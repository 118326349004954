import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { orderBy } from "lodash";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { HorsesFinalPositions } from "../../common/horsesPositions";
import { FinalResults } from "../../common/raceResults";
import { styles } from "./styles";
import FooterNextRace from "./components/FooterNextRace";
import FooterSpecialRace from "./components/FooterSpecialRace";
import { RaceDataContext } from "../../../contexts/raceData";
import { format } from "date-fns";

const FinalStatsRenderer = (props) => {
  const horsePositions = [];
  const { camera, time, horses, entities } = props;
  const [searchParams] = useSearchParams();
  const isSpecificRace = !!searchParams.get("raceid");
  const raceData = useContext(RaceDataContext);
  const horseObjs = [];
  if (horses) {
    horses.forEach((entity, key) => {
      horsePositions.push(entities[entity].model.position.x);
      horseObjs.push(entities[entity]);
    });
  }

  if (time && time.current >= 100000 && !isSpecificRace) {
    return null;
  }
  if (horseObjs.length === 0) {
    return null;
  }
  const startHourMinute = new Date(raceData.start);

  if (
    time.current > 0 &&
    (horsePositions.find((x) => x < 61) === undefined || time.current > 75000)
  ) {
    const horseWinner = orderBy(horseObjs, (o) => o.data.finalTime, ["ASC"])[0];
    const timeRemaning = () => {
      const currentTime = Math.trunc(
        Math.trunc(
          time
            ? time.current != 0
              ? (isSpecificRace ? 180000 : 100000) - time.current
              : 0
            : 0
        ) / 1000
      );
      const minutes = Math.floor(currentTime / 60);
      const seconds = Math.floor(currentTime % 60);
      return `${minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })}:${seconds.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })}`;
    };

    const date = format(startHourMinute, "| dd LLL yy | HH:mm");
    return (
      <AnimatePresence>
        <motion.div
          key="sarasa"
          style={styles.hud}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <div style={styles.RaceTittle}>
            <img
              height={"38px"}
              src={raceData.track.iconImage }
            />
            <span style={{lineHeight: 1}}>
              Race {raceData.name} {date}
            </span>
          </div>
            <FinalResults
              raceData={raceData}
              horseObjs={orderBy(horseObjs, (o) => o.data.finalTime, ["ASC"])}
              time={time}
              fontSize="1.05vw"
            />
          {isSpecificRace ? (
            <FooterSpecialRace time={time} />
          ) : (
            <FooterNextRace timeRemaning={timeRemaning} />
          )}
        </motion.div>
      </AnimatePresence>
    );
  } else {
    return null;
  }
};

export default (raceData) => {
  return { renderer: <FinalStatsRenderer raceData={raceData} /> };
};

// Verde de ganador split #7fff8e
// Violeta ganador de record split #f67fff
