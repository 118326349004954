import React from "react";
import { Reorder } from "framer-motion/dist/framer-motion";
import lodash from "lodash";
import { styles } from "./styles";
import GateNumberBox from "../GateBox";

const HorsesPositions = (props) => {
  const {
    horseObjs,
    showTittle = true,
    showNumbers = true,
    showBackground = true,
    horseWidth,
    fontSize,
    placeNumbers = false,
    horseObjsNextRace,
  } = props;

  return (
    <div
      style={styles.horseMiniatureColumn(showTittle, showBackground, fontSize)}
    >
      {showTittle && (
        <div style={styles.positionsTittle}>
          <p>STANDINGS</p>
        </div>
      )}
      <div style={styles.horsePositionsContainer(horseObjsNextRace)}>
        {showNumbers && (
          <div style={styles.numberPositions(placeNumbers)}>
            {(horseObjsNextRace ? horseObjsNextRace : horseObjs).map(
              (obj, index) => (
                <span>{index + 1}</span>
              )
            )}
          </div>
        )}
        <Reorder.Group
          axis="y"
          as="div"
          values={horseObjs}
          style={styles.horseMiniatureContainer(
            horseObjsNextRace ? true : false
          )}
        >
          {horseObjsNextRace
            ? horseObjsNextRace.map((obj) => {
                return (
                  <div style={styles.horseMiniaturesNextRace}>
                    <img
                      alt={"horseMiniature"}
                      style={styles.miniatures(horseWidth)}
                      src={obj.coin.iconImage}
                    ></img>
                    <div style={styles.horseName}>
                      {obj.coin.symbol.split("USDT")[0]}
                    </div>
                  </div>
                );
              })
            : lodash
                .sortBy(horseObjs, [
                  function (o) {
                    return -o.model.position.x;
                  },
                ])
                .map((horseObj, index) => {
                  return (
                    <Reorder.Item
                      as="div"
                      key={horseObj.data.id}
                      value={horseObj.model.position.x}
                      dragListener={false}
                    >
                      <div style={styles.horseMiniatures}>
                        <img
                          alt={"horseMiniature"}
                          style={styles.miniatures(horseWidth)}
                          src={horseObj.data.coin.iconImage}
                        ></img>
                        <div style={styles.horseName}>
                          {horseObj.data.coin.symbol.split("USDT")[0]}
                        </div>
                      </div>
                    </Reorder.Item>
                  );
                })}
        </Reorder.Group>
      </div>
    </div>
  );
};

export default HorsesPositions;

export const HorsesFinalPositions = (props) => {
  const {
    horseObjs,
    showTittle = true,
    showNumbers = true,
    showBackground = true,
    horseWidth,
    fontSize,
    raceData,
    placeNumbers = false,
    horseObjsNextRace,
  } = props;

  const horseObjects = lodash.orderBy(
    raceData.participants,
    (o) => o.finalTime,
    ["ASC"]
  );

  return (
    <div
      style={styles.horseMiniatureColumn(showTittle, showBackground, fontSize)}
    >
      {showTittle && (
        <div style={styles.positionsTittle}>
          <p>STANDINGS</p>
        </div>
      )}
      <div style={styles.horsePositionsContainer(horseObjsNextRace)}>
        {showNumbers && (
          <div style={styles.numberPositions(placeNumbers)}>
            {(horseObjsNextRace ? horseObjsNextRace : horseObjects).map(
              (obj, index) => (
                <span>{index + 1}</span>
              )
            )}
          </div>
        )}
        <Reorder.Group
          axis="y"
          as="div"
          values={horseObjs}
          style={styles.horseMiniatureContainer(
            horseObjsNextRace ? true : false
          )}
        >
          {horseObjects.map((obj) => {
            return (
              <div style={styles.horseMiniatures}>
                <img
                  alt={"horseMiniature"}
                  style={styles.miniatures(horseWidth)}
                  src={obj.coin.horseImage}
                ></img>
                <div style={styles.horseName}>
                  {obj.coin.symbol.split("USDT")[0]}
                </div>
              </div>
            );
          })}
        </Reorder.Group>
      </div>
    </div>
  );
};

export const HorsesNextRacePositions = (props) => {
  const { horseWidth, horseObjsNextRace, setClickedHorse } = props;

  return (
    <div style={styles.horseContainer}>
      {horseObjsNextRace.map((obj, index) => {
        return (
          <div style={styles.horses} key={index}>
            <GateNumberBox
              gate={index + 1}
              gateNumberStyle={index}
              width="24px"
              height="24px"
              fontSize="1.2em"
              onClick={() => setClickedHorse(index)}
              margin="auto"
            />
            <img
              alt={"horseMiniature"}
              style={{ width: "100%" }}
              src={obj.coin.horseImage}
              onClick={() => setClickedHorse(index)}
            ></img>
            <div
              style={styles.horseSymbol}
              onClick={() => setClickedHorse(index)}
            >
              {obj.coin.symbol.split("USDT")[0]}
            </div>
          </div>
        );
      })}
    </div>
  );
};
