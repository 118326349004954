import * as THREE from "three";
import { cloneTexture, add, promisifyLoader } from "../../../utils/three";

const loader = promisifyLoader(new THREE.TextureLoader(window.manager));

export default async ({ parent, y = 0, track }) => {
  const { floorStartImage, floorMiddleImage, floorFinishImage } = track;
  const preloadedTexture = loader.load(floorMiddleImage);
  const preloadedTextureFinish = loader.load(floorFinishImage);
  const preloadedTextureStart = loader.load(floorStartImage);

  // Beginning

  const preStartGeometry = new THREE.PlaneGeometry(2, 2.8);
  const preStartMaterial = new THREE.MeshBasicMaterial();
  const preStartTexture = cloneTexture(await Promise.resolve(preloadedTexture));

  preStartTexture.wrapS = THREE.RepeatWrapping;
  preStartTexture.wrapT = THREE.RepeatWrapping;
  preStartTexture.repeat.set(2, 1);
  preStartMaterial.map = preStartTexture;
  preStartMaterial.needsUpdate = true;
  const preStartMesh = new THREE.Mesh(preStartGeometry, preStartMaterial);

  preStartMesh.position.x = -2;
  preStartMesh.position.y = y ? y : -0.13;
  preStartMesh.position.z = -0.35;

  preStartMesh.rotateX(-90 * (Math.PI / 180));

  add(parent, preStartMesh);

  // Start

  const startGeometry = new THREE.PlaneGeometry(2, 2.8);
  const startMaterial = new THREE.MeshBasicMaterial();
  const startTexture = cloneTexture(
    await Promise.resolve(preloadedTextureStart)
  );

  startTexture.wrapS = THREE.RepeatWrapping;
  startTexture.wrapT = THREE.RepeatWrapping;
  startTexture.repeat.set(1, 1);
  startMaterial.map = startTexture;
  startMaterial.needsUpdate = true;
  const startMesh = new THREE.Mesh(startGeometry, startMaterial);

  startMesh.position.x = 0;
  startMesh.position.y = y ? y : -0.13;
  startMesh.position.z = -0.35;
  startMesh.rotateX(-90 * (Math.PI / 180));

  add(parent, startMesh);

  // Middle
  const geometry = new THREE.PlaneGeometry(58, 2.8);
  const material = new THREE.MeshBasicMaterial();
  const texture = cloneTexture(await Promise.resolve(preloadedTexture));

  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(58, 1);
  material.map = texture;
  material.needsUpdate = true;
  const cylinder = new THREE.Mesh(geometry, material);

  cylinder.position.x = 30;
  cylinder.position.y = y ? y : -0.13;
  cylinder.position.z = -0.35;

  cylinder.rotateX(-90 * (Math.PI / 180));

  add(parent, cylinder);

  // Finish

  const geometry2 = new THREE.PlaneGeometry(2, 2.8);
  const material2 = new THREE.MeshBasicMaterial();

  const textureFinish = cloneTexture(
    await Promise.resolve(preloadedTextureFinish)
  );
  material2.map = textureFinish;
  material2.needsUpdate = true;
  const cylinder2 = new THREE.Mesh(geometry2, material2);

  cylinder2.position.x = 60;
  cylinder2.position.y = y ? y : -0.13;
  cylinder2.position.z = -0.35;

  cylinder2.rotateX(-90 * (Math.PI / 180));

  add(parent, cylinder2);

  // After end

  const afterEndGeometry = new THREE.PlaneGeometry(2, 2.8);
  const afterEndMaterial = new THREE.MeshBasicMaterial();
  const textureAfterFinish = cloneTexture(
    await Promise.resolve(preloadedTexture)
  );

  textureAfterFinish.wrapS = THREE.RepeatWrapping;
  textureAfterFinish.wrapT = THREE.RepeatWrapping;
  textureAfterFinish.repeat.set(2, 1);
  afterEndMaterial.map = textureAfterFinish;
  afterEndMaterial.needsUpdate = true;
  const afterEndMesh = new THREE.Mesh(afterEndGeometry, afterEndMaterial);

  afterEndMesh.position.x = 62;
  afterEndMesh.position.y = y ? y : -0.13;
  afterEndMesh.position.z = -0.35;

  afterEndMesh.rotateX(-90 * (Math.PI / 180));

  add(parent, afterEndMesh);

  return {
    model: cylinder,
  };
};
