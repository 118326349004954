import React from "react";
import { styles } from "../styles";
import { useLocation, withRouter } from "react-router-dom";

const FooterSpecialRace = ({ time }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const raceId = queryParams.get("raceid");
  if (time.current > 102000 && window.soundOn) {
    window.toggleMute();
  }
  return (
    <div style={styles.specialRace}>
      <div
        onClick={() => {
          const previousRace = Number(raceId) - 1;
          queryParams.set("raceid", previousRace);
          window.location.href = `${
            window.location.pathname
          }?${queryParams.toString()}`;
        }}
        style={{
          display: "flex",
          placeItems: "center",
          gap: "7px",
          marginRight: "10px",
          cursor: "pointer",
          fontSize: ".7em",
        }}
      >
        <img
          src={`https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/ico_previous.png`}
          height={"25px"}
        />
        <text>Previous Race</text>
      </div>
      <div
        onClick={() => {
          const nextRace = Number(raceId) + 1;
          queryParams.set("raceid", nextRace);
          window.location.href = `${
            window.location.pathname
          }?${queryParams.toString()}`;
        }}
        style={{
          display: "flex",
          placeItems: "center",
          gap: "7px",
          marginRight: "10px",
          cursor: "pointer",
          fontSize: ".7em",
        }}
      >
        <img
          src={`https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/ico_next.png`}
          height={"25px"}
        />
        <text>Next Race</text>
      </div>
      <div
        onClick={() => {
          window.location.reload();
        }}
        style={{
          display: "flex",
          placeItems: "center",
          gap: "7px",
          marginRight: "10px",
          cursor: "pointer",
          fontSize: ".7em",
        }}
      >
        <img
          src={`https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/ico_replay.png`}
          height={"25px"}
        />
        <text>Replay</text>
      </div>
      <div
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          alert("Carrera copiada al portapapeles");
        }}
        style={{
          display: "flex",
          placeItems: "center",
          gap: "7px",
          marginRight: "10px",
          cursor: "pointer",
          fontSize: ".7em",
        }}
      >
        <img
          src={`https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/ico_share.png`}
          height={"25px"}
        />
        <text>Share</text>
      </div>
      <div
        onClick={() => {
          window.location.replace("/");
        }}
        style={{
          display: "flex",
          placeItems: "center",
          gap: "7px",
          marginRight: "10px",
          cursor: "pointer",
          fontSize: ".7em",
        }}
      >
        <img
          src={`https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/ico_live.png`}
          height={"25px"}
        />
        <text>Live</text>
      </div>
    </div>
  );
};

export default FooterSpecialRace;
