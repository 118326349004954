import React from "react";
import { styles } from "../styles";
import BetButton from "../../../common/betButton";

const FooterNextRace = (props) => {
  const { timeRemaning } = props;
  return (
    <div style={styles.nextRaceFooter}>
      <span style={styles.nexRaceCooldown}>Next race in {timeRemaning()}</span>
      <BetButton
        onClick={() => {
          window.open("https://cryptodromo.com/bets", "_blank", "noreferrer");
        }}
        width="90%"
        height="45%"
      />
      
    </div>
  );
};

export default FooterNextRace;
