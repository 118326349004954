import React, { useState } from "react";
import { styles } from "../styles";
import ImageSlider from "../../../common/carousel/Carousel";
import { HorsesNextRacePositions } from "../../../common/horsesPositions/index";
import HorseInfo from "./HorseInfo";

const NextRaceInfo = (props) => {
  const { horseinfo, setHorseinfo, horseObjs, raceData } = props;
  const [clickedHorse, setClickedHorse] = useState(0);
  // const stats = horseObjs[horseinfo].coin.stats[horseObjs[horseinfo].coin.stats.length-1]
  const stats = horseObjs[horseinfo].coin.lastStat;
  return (
    <div style={styles.nextRaceContent}>
      <HorsesNextRacePositions
        horseObjsNextRace={horseObjs}
        setClickedHorse={setClickedHorse}
      />
      <ImageSlider
        horses={horseObjs}
        setHorseinfo={setHorseinfo}
        clickedHorse={clickedHorse}
        raceData={raceData}
      />
      <HorseInfo horse={horseObjs[horseinfo].coin} stats={stats} />
    </div>
  );
};

export default NextRaceInfo;
