const findDose = (time, doses) => {
    return doses.find((dose) => dose.timeStart < time && dose.timeEnd >= time) || doses[0]
}
const Race = (entities, args) => {
    const horses = Object.keys(entities).filter((name) => name.includes('horse'));
    const { time, dispatch } = args;

    if (time.current > 175000) {
        dispatch({ type: 'transition' })
        return entities
    }

    if (time.current > 75000) {
        dispatch({ type: 'ended' })
        horses.forEach(entity => {
            const thisEntity = entities[entity]
            if (thisEntity.model.position.x < 62) {
                thisEntity.model.position.x = 62
            }
        })
        if (!window.raceData.finalTimeRecord) {
            dispatch({ type: 'fetchSecondPart' })
        }
        return entities
    }

    if (time.current > 43000 && !window.raceData.finalTimeRecord) {
        dispatch({ type: 'fetchSecondPart' })
    }

    const horsesData = window.raceData.participants

    horses.forEach(entity => {
        const thisEntity = entities[entity]
        const thisEntityDoses = horsesData.find((item) => item.id === thisEntity.data.id).stats
        if (time.current < 0) {
            return
        }
        const dose = findDose(time.current, thisEntityDoses)
        if (time.current < 5000) {
            const partialPotition = dose.positionEnd * 60 * Math.pow(time.current / 5000, 2)
            const move = partialPotition - thisEntity.model.position.x;
            entities[entity].model.translateX(move)
            return;
        }

        if (time.current >= 5000 && time.current < 60000) {
            const currentDose = Math.floor(time.current / 5000)
            const doseStartPosition = dose.positionStart * 60
            const partialPotition = dose.slope * (time.current - currentDose * 5000) + doseStartPosition
            const move = partialPotition - thisEntity.model.position.x;
            entities[entity].model.translateX(move)
        } else {
            const doseStartPosition = dose.positionStart * 60
            const partialPotition = dose.slope * (time.current - 60000) + doseStartPosition
            const move = partialPotition - thisEntity.model.position.x;
            entities[entity].model.translateX(move)
        }
    });
    return entities;
};

export default Race;