import React, { PureComponent } from "react";
import * as THREE from 'three';
import EffectComposer from "./effect-composer";
import RenderPass from "./passes/render-pass";
import _ from "lodash";

//-- https://medium.com/@colesayershapiro/using-three-js-in-react-6cb71e87bdf4
//-- https://medium.com/@summerdeehan/a-beginners-guide-to-using-three-js-react-and-webgl-to-build-a-3d-application-with-interaction-5d7b2c7ca89a
//-- https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/Tutorial/Getting_started_with_WebGL

class ThreeView extends PureComponent {

  componentDidMount() {
    const container = this.refs.container;
    const width = container.clientWidth;
    const height = container.clientHeight;
    const dpr = window.devicePixelRatio;
    this.props.camera.resize(width, height, dpr);
    this.renderer = new THREE.WebGLRenderer({ antialias: false });
    this.renderer.setPixelRatio(dpr);
    this.renderer.setSize(width, height);
    this.renderer.setClearColor(0x020202, 1.0);
    this.composer = new EffectComposer(this.renderer);

    const passes = [
      new RenderPass(this.props.scene, this.props.camera),
      ...this.props.passes
    ]

    passes.forEach(p => this.composer.addPass(p))
    passes[passes.length - 1].renderToScreen = true;

    window.addEventListener("resize", this.onResize);

    container.appendChild(this.renderer.domElement)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);

    const container = this.refs.container;
    container.removeChild(this.renderer.domElement)
  }

  onResize = () => {
    const container = this.refs.container;
    const width = container.clientWidth;
    const height = container.clientHeight;
    const dpr = window.devicePixelRatio;

    this.props.camera.resize(width, height, dpr);
    this.renderer.setPixelRatio(dpr);
    this.renderer.setSize(width, height);
  };

  render() {
    if (this.renderer) {
      this.renderer.render(this.props.scene, this.props.camera);
    }

    return (
      <>
        <div ref={"container"} style={css.container}>
          {this.props.children}
        </div>
      </>
    );
  }
}

const css = {
  container: {
    // height: "768px",
    // width: "1366px",
    // overflow: "hidden",
    // marginTop: "80px"
    height: "100%",
    width: "100%",
    overflow: "hidden",
  }
}

const renderCountdown = (entities, window) => {
  if (!entities.countdown) return null;

  const countdown = entities.countdown;

  if (typeof countdown.renderer === "object")
    return <countdown.renderer.type key={"countdown"} {...countdown} window={window} />;
  else if (typeof countdown.renderer === "function")
    return <countdown.renderer key={"hud"} {...countdown} window={window} />;
};

const renderHUD = (entities, window) => {
  if (!entities.hud) return null;
  if (window.hudOff) return null;
  const hud = entities.hud;

  if (typeof hud.renderer === "object")
    return <hud.renderer.type key={"hud"} {...hud} window={window} />;
  else if (typeof hud.renderer === "function")
    return <hud.renderer key={"hud"} {...hud} window={window} />;
};

const renderMenuLayout = (entities, window) => {
  if (!entities.menuLayout) return null;

  const menuLayout = entities.menuLayout;

  if (typeof menuLayout.renderer === "object")
    return <menuLayout.renderer.type key={"menuLayout"} {...menuLayout} window={window} />;
  else if (typeof menuLayout.renderer === "function")
    return <menuLayout.renderer key={"menuLayout"} {...menuLayout} window={window} />;
};

const renderInitialStats = (entities, window) => {
  if (!entities.startStats) return null;

  const startStats = entities.startStats;

  if (typeof startStats.renderer === "object")
    return <startStats.renderer.type key={"startStats"} {...startStats} window={window} />;
  else if (typeof startStats.renderer === "function")
    return <startStats.renderer key={"startStats"} {...startStats} window={window} />;
};

const renderFinalStats = (entities, window) => {
  if (!entities.finalStats) return null;

  const finalStats = entities.finalStats;

  if (typeof finalStats.renderer === "object")
    return <finalStats.renderer.type key={"finalStats"} {...finalStats} window={window} />;
  else if (typeof finalStats.renderer === "function")
    return <finalStats.renderer key={"finalStats"} {...finalStats} window={window} />;
};


const renderTimer = (entities, window) => {
  if (!entities.timer) return null;

  const timer = entities.timer;

  if (typeof timer.renderer === "object")
    return <timer.renderer.type key={"timer"} {...timer} window={window} />;
  else if (typeof timer.renderer === "function")
    return <timer.renderer key={"timer"} {...timer} window={window} />;
};

const ThreeJSRenderer = (...passes) => (entities, window) => {
  if (!entities) return null;
  return [
    <ThreeView
      passes={_.flatten(passes)}
      key={"threeView"}
      scene={entities.scene}
      camera={entities.camera}
    >
      {renderHUD(entities, window)}
      {renderCountdown(entities, window)}
      {renderTimer(entities, window)}
      {renderInitialStats(entities, window)}
      {renderFinalStats(entities, window)}
      {renderMenuLayout(entities, window)}
    </ThreeView>,
  ];
};

export default ThreeJSRenderer;
