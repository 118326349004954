import * as THREE from "three";
import Sprite from "../sprite/index";
import { promisifyLoader } from "../../../utils/three";

const loader = promisifyLoader(new THREE.TextureLoader(window.manager));

export default async ({ parent, world, x = 0, y = 0, z = 0, doses, image, name, data }) => {

	const spriteSheet = loader.load(image || 'https://cryptodromo-dev.s3.us-east-2.amazonaws.com/sprites/spritesheet_default.png');
	const sprite = await Sprite({
		parent,
		x,
		y,
		z,
		spriteSheet,
		columns: 1,
		rows: 12,
		actions: {
			idle: {
				start: { row: 0, column: 0 }
			},
			walk: {
				start: { row: 0, column: 0 },
				end: { row: 0, column: 11 },
				speed: 0.1
			},
			trot: {
				start: { row: 11, column: 0 },
				end: { row: 0, column: 0 },
				speed: 0.2
			},
			canter: {
				start: { row: 0, column: 0 },
				end: { row: 0, column: 11 },
				speed: 0.3
			},
			gallop: {
				start: { row: 0, column: 0 },
				end: { row: 0, column: 11 },
				speed: 0.5
			},
		}
	});

	sprite.timelines.controls = {
		while: true,
		update(self, entities, { directions }, { gamepadController }) {
			self.actions.trot();
		}
	};
	// const sublastDose = doses.shift()
	// sublastDose.positionEnd = 1;
	// const lastDose = { timeStart: sublastDose.timeEnd, timeEnd: sublastDose.timeEnd + 10000, positionStart: sublastDose.positionEnd, positionEnd: sublastDose.positionEnd + 0.20, speedStart: sublastDose.speedEnd, speedEnd: sublastDose.speedEnd, slope: 0 }
	sprite.doses = doses
	sprite.name = `horse${name}`
	sprite.originalName = name
	sprite.data = data
	return sprite;
};
