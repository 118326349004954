import React from "react";
import { styles } from "../styles";

const HorseInfo = ({ horse, stats }) => {
  return (
    <div style={styles.horseInfoContainer}>
      <div style={styles.horseInfoHead}>
        <div style={styles.horseInfoSymbol}>
          <span>{horse.symbol.split("USDT")}</span>
          <div
            style={styles.horseInfoBall(horse.primaryColor, horse.secondaryColor)}
          />
        </div>
        <span style={styles.horseInfoName}>{horse.name.split("USDT")}</span>
      </div>
      <div style={styles.horseStatsInfo}>
        <div style={styles.horseInfo}>
          <text style={styles.horseInfoTittle}>CURRENT RANK</text>
          <div style={styles.horseInfoRakingContent}>
            <div style={styles.horseInfoContet}>
              <text>{stats.rankingPosition}</text>
              <span>°</span>
            </div>
            <text>({stats.janchIndex})</text>
          </div>
        </div>
        <div style={styles.horseInfo}>
          <text style={styles.horseInfoTittle}>DAILY ACTIVITY</text>
          <text style={styles.horseInfoContet}>{stats.racesTotal} races</text>
        </div>
        <div style={styles.horseInfo}>
          <text style={styles.horseInfoTittle}>BEST PERFORMANCES</text>
          <div style={styles.lastDayPerformaceContent}>
            <img
              src="https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/win_1.png"
              width="26px"
              height="26px"
            />
            <div display="flex" height="min-content">
              <text>Winner</text>
            </div>
            <text fontWeight="500">{stats.racesFirstPosition}</text>
          </div>
          <div style={styles.lastDayPerformaceContent}>
            <img
              src="https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/win_2.png"
              width="26px"
              height="26px"
            />
            <div display="flex" height="min-content">
              <text>Runner-up</text>
            </div>
            <text fontWeight="500">{stats.racesSecondPosition}</text>
          </div>
          <div style={styles.lastDayPerformaceContent}>
            <img
              src="https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/win_3.png"
              width="26px"
              height="26px"
            />
            <div display="flex" height="min-content">
              <text>Third Place</text>
            </div>
            <text fontWeight="500">{stats.racesThirdPosition}</text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorseInfo;
