import * as THREE from "three";
import { add } from "../../../utils/three";

export default ({ parent, track }) => {
  const loader = new THREE.TextureLoader(window.manager);
  const { leftBushesFinishImage, leftBushesMiddleImage, leftBushesStartImage } =
    track;

  const zlevel = -0.25;
  const ylevel = 0.365;
  const xShift = 0;

  const beginningGeometry = new THREE.PlaneGeometry(4, 1);
  const beginningMaterial = new THREE.MeshBasicMaterial();

  loader.load(leftBushesMiddleImage, function (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(1, 1);
    beginningMaterial.map = texture;
    beginningMaterial.depthWrite = false;
    beginningMaterial.transparent = true;
    beginningMaterial.needsUpdate = true;
  });
  const beginningMesh = new THREE.Mesh(beginningGeometry, beginningMaterial);

  beginningMesh.position.x = -4 + xShift;
  beginningMesh.position.y = ylevel;
  beginningMesh.position.z = zlevel;
  add(parent, beginningMesh);

  // Start
  const startGeometry = new THREE.PlaneGeometry(2, 1);
  const startMaterial = new THREE.MeshBasicMaterial();

  loader.load(leftBushesStartImage, function (texture) {
    startMaterial.map = texture;
    startMaterial.depthWrite = false;
    startMaterial.transparent = true;
    startMaterial.needsUpdate = true;
  });
  const startMesh = new THREE.Mesh(startGeometry, startMaterial);

  startMesh.position.x = 0 + xShift;
  startMesh.position.y = ylevel;
  startMesh.position.z = zlevel;
  add(parent, startMesh);

  // Middle

  const geometry = new THREE.PlaneGeometry(58, 1);
  const material = new THREE.MeshBasicMaterial();

  loader.load(leftBushesMiddleImage, function (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(14.5, 1);
    material.map = texture;
    material.depthWrite = false;
    material.transparent = true;
    material.needsUpdate = true;
  });
  const cylinder = new THREE.Mesh(geometry, material);

  cylinder.position.x = 30 + xShift;
  cylinder.position.y = ylevel;
  cylinder.position.z = zlevel;

  add(parent, cylinder);

  // Finish
  const finishGeometry = new THREE.PlaneGeometry(2, 1);
  const finishMaterial = new THREE.MeshBasicMaterial();

  loader.load(leftBushesFinishImage, function (texture) {
    finishMaterial.map = texture;
    finishMaterial.depthWrite = false;
    finishMaterial.transparent = true;
    finishMaterial.needsUpdate = true;
  });
  const finishMesh = new THREE.Mesh(finishGeometry, finishMaterial);

  finishMesh.position.x = 60 + xShift;
  finishMesh.position.y = ylevel;
  finishMesh.position.z = zlevel;

  add(parent, finishMesh);

  // After finish
  const afterFinishGeometry = new THREE.PlaneGeometry(4, 1);
  const afterFinishMaterial = new THREE.MeshBasicMaterial();

  loader.load(leftBushesMiddleImage, function (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(1, 1);
    afterFinishMaterial.map = texture;
    afterFinishMaterial.depthWrite = false;
    afterFinishMaterial.transparent = true;
    afterFinishMaterial.needsUpdate = true;
  });
  const afterFinishMesh = new THREE.Mesh(
    afterFinishGeometry,
    afterFinishMaterial
  );

  afterFinishMesh.position.x = 62 + xShift;
  afterFinishMesh.position.y = ylevel;
  afterFinishMesh.position.z = zlevel;

  add(parent, afterFinishMesh);

  return {
    model: cylinder,
  };
};
