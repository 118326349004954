import * as THREE from "three";

export default (cameraPositionX) => {
	const camera = new THREE.PerspectiveCamera(
		50,
		1.77,
		0.01,
		2000
	);
	camera.position.x = cameraPositionX||60
	const lookAt = camera.lookAt;

	// //-- Overriding the lookAt function so I always
	// //-- have a quick reference to the lookAt vector
	camera.lookAt = vec => {
		lookAt.apply(camera, [vec]);
		camera.target = vec;
	};

	camera.timelines = {};

	camera.resize = (width, height, dpr) => {
		camera.aspect = 1.77;
		camera.updateProjectionMatrix();
	};

	return camera;
};
