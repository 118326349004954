import React, { useContext, useState, useMemo } from "react";
import fscreen from "fscreen";
import { Button } from "react-player-controls";
import { format } from "date-fns";
import { styles } from "./styles";
import SpeakerOn from "./icons/SpeakerOn";
import SpeakerOff from "./icons/SpeakerOff ";
import InformationCirculeOn from "./icons/InformationCirculeOn";
import ArrowsPointingIn from "./icons/ArrowsPointingIn";
import ArrowsPointingOut from "./icons/ArrowsPointingOut";
import InformationCirculeOff from "./icons/InformationCirculeOff";
import { RaceDataContext } from "../../../contexts/raceData";

const MenuLayout = (props) => {
  const { time } = props;
  const nextRaceData = window.nextRaceData;
  // State hooks
  const [opacity, setOpacity] = useState(0);
  const raceData = useContext(RaceDataContext);

  // date
  const date = format(new Date(raceData.start), "| dd LLL yy | kk:mm |");

  // Helper functions
  const enterFullscreen = () => {
    if (fscreen.fullscreenEnabled && !fscreen.fullscreenElement) {
      fscreen.addEventListener("fullscreenchange", handler, false);
      fscreen.requestFullscreen(window.refContainer.current);
    } else {
      fscreen.exitFullscreen();
    }
    function handler() {
      if (fscreen.fullscreenElement !== null) {
        // console.log("Entered fullscreen mode");
      } else {
        // console.log("Exited fullscreen mode");
      }
    }
  };
  const removeHUD = () => {
    window.hudOff = !window.hudOff;
  };

  const getSeconds = () => {
    const currentTime = Math.trunc(
      Math.trunc(time ? (time.current >= 0 ? time.current : 0) : 0) / 1000
    );
    return currentTime.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
  };
  const getMilliseconds = () => {
    const currentTime = Math.trunc(
      Math.trunc(time ? (time.current >= 0 ? time.current : 0) : 0) % 1000
    );
    return currentTime.toLocaleString("en-US", {
      minimumIntegerDigits: 3,
    });
  };
  // Use memo to avoid unnecessary re-renders
  let winner = null;
  winner = useMemo(() => {
    if (raceData.finalTimeRecord) {
      return raceData.participants.find(
        (part) => part.id === raceData.finalTimeRecord
      );
    }
  }, [raceData.finalTimeRecord]);
  return (
    <div
      style={{ ...styles.hud(time ? time.current : undefined, opacity) }}
      onMouseEnter={(e) => setOpacity(1)}
      onMouseLeave={(e) => time.current > 62000 && setOpacity(0)}
    >
      <div style={{ display: "flex", marginLeft: "10px" }}>
        <Button style={styles.button} onClick={() => window.toggleMute()}>
          {window.soundOn ? (
            <SpeakerOn width="20px" height="20px" />
          ) : (
            <SpeakerOff width="20px" height="20px" />
          )}
        </Button>
        <Button style={styles.button} onClick={removeHUD}>
          {window.hudOff ? (
            <InformationCirculeOff width="20px" height="20px" />
          ) : (
            <InformationCirculeOn width="20px" height="20px" />
          )}
        </Button>
      </div>
      <div
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          marginLeft: "8%",
        }}
      >
        {time && time.current <= 100000
          ? `Race ${raceData.name} ${date} ${raceData.track.name}`
          : nextRaceData
          ? `Race ${nextRaceData.name} ${date} ${nextRaceData.track.name}`
          : ""}
      </div>
      <div
        style={{
          marginRight: "15px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div
          style={{
            marginRight: "30px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            src="https://crp-prd.s3.sa-east-1.amazonaws.com/texturas/cronometro_ico.png"
            width={"20px"}
          />
          {time && time.current <= 100000
            ? winner && time && time.current / 1000 >= winner.finalTime
              ? `${winner.finalTime}`
              : `${getSeconds()}:${getMilliseconds()}`
            : "00.000"}
        </div>
        <Button style={styles.button} onClick={enterFullscreen}>
          {fscreen.fullscreenElement === null ? (
            <ArrowsPointingOut width="20px" height="20px" />
          ) : (
            <ArrowsPointingIn width="20px" height="20px" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default () => {
  return { renderer: <MenuLayout /> };
};
