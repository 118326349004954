import * as THREE from "three";
import { add, cloneTexture, promisifyLoader } from "../../../utils/three";

const loader = promisifyLoader(new THREE.TextureLoader(window.manager));

export default async ({ parent, track }) => {
  const { backgroundEndImage, backgroundMiddleImage, backgroundStartImage } =
    track;
  const textureMiddle = loader.load(backgroundMiddleImage);
  const textureEnd = loader.load(backgroundEndImage);
  const textureStart = loader.load(backgroundStartImage);

  const tiltAngle = -10 * (Math.PI / 180);
  const zlevel = -2;
  const geometry = new THREE.PlaneGeometry(60, 1.2);
  const material = new THREE.MeshBasicMaterial();

  const texture = cloneTexture(await Promise.resolve(textureMiddle));
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(15, 1);
  material.map = texture;
  material.needsUpdate = true;
  const cylinder = new THREE.Mesh(geometry, material);

  cylinder.position.x = 30;
  cylinder.position.y = 0.33;
  cylinder.position.z = zlevel;
  cylinder.rotateX(tiltAngle);

  add(parent, cylinder);

  const geometry2 = new THREE.PlaneGeometry(4, 1.2);
  const material2 = new THREE.MeshBasicMaterial();

  const textureStartCloned = cloneTexture(await Promise.resolve(textureStart));
  material2.map = textureStartCloned;
  material2.needsUpdate = true;
  const cylinder2 = new THREE.Mesh(geometry2, material2);

  cylinder2.position.x = -2;
  cylinder2.position.y = 0.33;
  cylinder2.position.z = zlevel;
  cylinder2.rotateX(tiltAngle);

  add(parent, cylinder2);

  const geometry3 = new THREE.PlaneGeometry(4, 1.2);
  const material3 = new THREE.MeshBasicMaterial();

  const textureEndCloned = cloneTexture(await Promise.resolve(textureEnd));

  material3.map = textureEndCloned;
  material3.needsUpdate = true;
  const cylinder3 = new THREE.Mesh(geometry3, material3);

  cylinder3.position.x = 62;
  cylinder3.position.y = 0.33;
  cylinder3.position.z = zlevel;
  cylinder3.rotateX(tiltAngle);

  add(parent, cylinder3);

  return {
    model: cylinder,
  };
};
