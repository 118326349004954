const HUD = (entities, args) => {
  const hud = entities.hud;
  if (hud) {
    hud.gamepadController = args.gamepadController;
    hud.keyboardController = args.keyboardController;
    hud.mouseController = args.mouseController;
    hud.camera = entities.camera
    hud.time = args.time
    hud.horses = Object.keys(entities).filter((name) => name.includes('horse'));
    hud.entities = entities
  }

  const menuLayout = entities.menuLayout;
  if (menuLayout) {
    menuLayout.gamepadController = args.gamepadController;
    menuLayout.keyboardController = args.keyboardController;
    menuLayout.mouseController = args.mouseController;
    menuLayout.camera = entities.camera
    menuLayout.time = args.time
    menuLayout.horses = Object.keys(entities).filter((name) => name.includes('horse'));
    menuLayout.entities = entities
  }

  const countdown = entities.countdown;

  if (countdown) {
    countdown.time = args.time
}
  const timer = entities.timer;
  const startStats = entities.startStats;
  const finalStats = entities.finalStats;
  
  if (timer) {
    timer.time = args.time
    timer.horses = Object.keys(entities).filter((name) => name.includes('horse'));
    timer.entities = entities
  }
  if (startStats) {
    startStats.time = args.time
    startStats.horses = Object.keys(entities).filter((name) => name.includes('horse'));
    startStats.entities = entities
  }
  if (finalStats) {
    finalStats.time = args.time
    finalStats.horses = Object.keys(entities).filter((name) => name.includes('horse'));
    finalStats.entities = entities
  }


  return entities;
};

export default HUD;