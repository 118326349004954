import React from "react";
import ReactDOM from "react-dom";
import * as ServiceWorker from "./service-worker.jsx";
import "./index.css";
import GameRoutes from "./gameRoutes.jsx";

ReactDOM.render(<GameRoutes />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ServiceWorker.register();
