import * as THREE from "three";
const textureFile =
  "https://cryptodromo-dev.s3.us-east-2.amazonaws.com/texturas/gatera_larga2.png";

export default ({ x = 0, y = 0, z = 0 }) => {
  const proportion = 0.592;
  const unit = 0.36;
  const geometry = new THREE.PlaneGeometry(unit * proportion, unit);

  const material = new THREE.MeshBasicMaterial();

  const loader = new THREE.TextureLoader(window.manager);
  loader.load(textureFile, function (texture) {
    material.map = texture;
    material.depthWrite = false;
    material.transparent = true;
    material.needsUpdate = true;
  });
  const cylinder = new THREE.Mesh(geometry, material);

  cylinder.position.x = x;
  cylinder.position.y = y;
  cylinder.position.z = z;

  return {
    model: cylinder,
  };
};
