import { rotateAroundPoint } from "../utils/three";

const Camera = ({
  yawSpeed = 0.01,
  pitchSpeed = 0.01,
  zoomSpeed = 0.02
} = {}) => {
  return (entities, { time }) => {
    if (!entities) {
      return entities
    }
    const camera = entities.camera;

    if (camera) {
      if (time.current < -5000) {
        // 70000
        // const doseStartPosition = dose.positionStart * 62
        // const partialPotition = dose.slope * (time.current - 60000) + doseStartPosition
        // const move = partialPotition - thisEntity.model.position.x;
        // entities[entity].model.translateX(move)
        // const slope = (0.69-camera.position.x)/1000
        // const distanceLeft = (camera.position.x-0.69)/10000;
        //          camera.translateX(slope);

      } else {
        let horseX = 0;
        const horses = Object.keys(entities).filter((name) => name.includes('horse'));
        horses.forEach(entity => {
          if (entities[entity].model.position.x > horseX)
            horseX = entities[entity].model.position.x
        });

        if (camera.position.x < 60 && horseX > 0.69)
          camera.translateX((horseX + 0.05 - camera.position.x) / 10);
      }
    }

    return entities;
  };
};

export default Camera;
