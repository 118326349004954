import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useSearchParams } from "react-router-dom";
import { styles } from "./styles";
import NextRaceInfo from "./components/NextRaceInfo";
import HorsesGrid from "./components/HorsesGrid";
import { format } from "date-fns";
import BetButton from "../../common/betButton";

const StartStatsRenderer = (props) => {
  const { time } = props;
  const raceData = window.nextRaceData;
  const [searchParams] = useSearchParams();
  const [horseinfo, setHorseinfo] = useState(0);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const isSpecificRace = !!searchParams.get("raceid");
  if (isSpecificRace) {
    // setShouldDisplay(false)
    return null;
  }
  if (!raceData) {
    // setShouldDisplay(false)
    return null;
  }
  if (!time) {
    // setShouldDisplay(false)
    return null;
  }
  const horseObjs = raceData.participants;
  if (time.current < 100000) {
    // setShouldDisplay(false)
    return null;
  }
  const startHourMinute = new Date(raceData.start);

  const timeRemaning = (finalTimer) => {
    const currentTime = Math.abs(
      Math.trunc(
        Math.trunc(
          time ? (time.current != 0 ? finalTimer - time.current : 0) : 0
        ) / 1000
      )
    );
    const minutes = Math.floor(currentTime / 60);
    const seconds = Math.floor(currentTime % 60);
    return `${minutes.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    })}:${seconds.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    })}`;
  };
  const rateCalculation = () => {
    return `Calculating odds${
      time.current < 157000 ? "." : time.current < 158000 ? ".." : "..."
    }`;
  };
  const date = format(startHourMinute, "| dd LLL yy | HH:mm");
  return (
    <AnimatePresence initial={true}>
      <motion.div
        key="modalStartStats"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={styles.hud}
        transition={{ duration: 2, ease: "easeOut" }}
      >
        <span style={styles.nextRaceTittle}>
          Race {raceData.name} {date}
        </span>
        {time.current < 160000 ? (
          <NextRaceInfo
            horseinfo={horseinfo}
            setHorseinfo={setHorseinfo}
            horseObjs={horseObjs}
            raceData={raceData}
          />
        ) : (
          <HorsesGrid horseObjs={horseObjs} />
        )}
        <div style={styles.nextRaceFooter}>
          <span style={styles.nexRaceCooldown}>
            {time.current < 156000
              ? `Betting closes in ${timeRemaning(156000)}`
              : time.current < 160000
              ? rateCalculation()
              : `Race starts in ${timeRemaning(175000)}`}
          </span>
          <BetButton
            width="90%"
            height="45%"
            disable={time.current < 156000}
            onClick={() => {
              time.current < 156000 &&
                window.open(
                  "https://cryptodromo.com/bets",
                  "_blank",
                  "noreferrer"
                );
            }}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
export default (raceData) => {
  return { renderer: <StartStatsRenderer raceData={raceData} /> };
};

// Verde de ganador split #7fff8e
// Violeta ganador de record split #f67fff
