import * as THREE from "three";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { GameEngine } from "react-game-engine";
import Renderer from "./graphics/renderer";
import Systems from "./systems";
import Entities from "./entities.jsx";
import "../index.css";
import CustomTimer from "./systems/customTimer";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { Howl } from "howler";
import { RaceDataContext } from "./contexts/raceData";
import { NextRaceDataContext } from "./contexts/nextRaceData";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import lodash from "lodash";

import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
function calculateSlope(v1, v2) {
  return (v2 - v1) / 5000;
}

// Howler.autoUnlock = false;

const timer = new CustomTimer();
let startingSecconds = -5; //new Date().getSeconds() - 5;
// let startingSecconds = 58//new Date().getSeconds() - 5;

const addHours = function (d, h) {
  d.setTime(d.getTime() + h * 60 * 60 * 1000);
  return d;
};

const Game = () => {
  const [raceData, setRaceData] = useState(null);
  const [nextRaceData, setNextRaceData] = useState(null);
  const [reloading, setReloading] = useState(false);
  const [fetchinSecondPart, setFetchinSecondPart] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [entities, setEntities] = useState([]);
  const [raceNumber, setRaceNumber] = useState(0);
  const [isMutted, setIsMutted] = useState(true);
  const [theSound, setTheSound] = useState(null);
  const [soundtrack2, setSoundtrack2] = useState(null);
  const [startTime, setStartTime] = useState(null);

  const refContainer = useRef();
  const startingTime = startingSecconds * -1000;
  useEffect(() => {
    timer.setStartingTime(startingTime);
    fetchRace();
  }, []);

  useEffect(() => {
    const soundtrack1 = new Howl({
      src: [
        "https://cryptodromo-dev.s3.us-east-2.amazonaws.com/audio/track1.mp3",
      ],
      loop: true,
      onplayerror: function () {},
    });
    soundtrack1.once("unlock", function () {});
    const soundtrack2 = new Howl({
      src: [
        "https://cryptodromo-dev.s3.us-east-2.amazonaws.com/audio/track2.mp3",
      ],
      loop: true,
      onplayerror: function () {},
    });
    soundtrack1.once("unlock", function () {});

    setTheSound(soundtrack1);
    setSoundtrack2(soundtrack2);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const manager = new THREE.LoadingManager();
  window.manager = manager;

  const stadium = searchParams.get("stadium") || 1;

  const fetchRace = async () => {
    let raceData = {};
    if (searchParams.get("raceid")) {
      const data = await fetch(
        `https://api.cryptodromo.com/races/${searchParams.get("raceid")}`
      );
      raceData = await data.json();
    } else {
      if (searchParams.get("previous")) {
        const data = await fetch(
          "https://api.cryptodromo.com/race/previous/results"
        );
        raceData = await data.json();
      } else {
        const data = await fetch("https://api.cryptodromo.com/race/results");
        raceData = await data.json();
      }
    }

    let currDate = new Date();
    let raceDate = new Date(raceData.start);
    let diffTime = differenceInMilliseconds(currDate, raceDate);
    diffTime = diffTime - 20000;
    if(searchParams.get("raceid")){
      diffTime = -5000
    }
    
    timer.setStartingTime(-diffTime);
    setStartTime(-diffTime);
    raceData.participants = lodash.sortBy(raceData.participants, "gate");

    raceData.participants.forEach((horse) => {
      horse.stats = _.orderBy(horse.stats, "timeStart", "desc");
      horse.stats.forEach((dose) => {
        dose.slope = calculateSlope(
          dose.positionStart * 60,
          dose.positionEnd * 60
        );
      });
      horse.stats.forEach((dose) => {
        dose.slope = dose.slope * 1;
      });
      horse.stats[0].slope = horse.stats[1].slope;
    });
    setRaceNumber(raceNumber + 1);
    setRaceData(raceData);
  };

  let gameEngineRef = React.createRef();
  window.gameEngineRef = gameEngineRef;
  window.refContainer = refContainer;

  useEffect(() => {
    window.raceData = raceData;
  }, [raceData]);

  const handleEvent = async (e) => {
    // console.log(e);
    if(searchParams.get("raceid")){
      return;
    }
    if (e.type === "ended") {
      setReloading(true);
      if (!reloading) {
        let data = {};
        data = await fetch("https://api.cryptodromo.com/race/next/results");
        const newRaceData = await data.json();
        // newRaceData.participants.forEach((horse) => {
        //   horse.stats = _.orderBy(horse.stats, "timeStart", "desc");
        //   horse.stats.forEach((dose) => {
        //     dose.slope = calculateSlope(
        //       dose.positionStart * 60,
        //       dose.positionEnd * 60
        //     );
        //   });
        //   horse.stats.forEach((dose) => {
        //     dose.slope = dose.slope * 1;
        //   });
        //   horse.stats[0].slope = horse.stats[1].slope;
        // });
        newRaceData.participants = lodash.sortBy(
          newRaceData.participants,
          "gate"
        );
        window.nextRaceData = newRaceData;
        setNextRaceData(newRaceData);
      }
    }
    if (e.type === "swapped") {
      setReloading(false);
      if(window.gameEngineRef && window.gameEngineRef.current){
        window.gameEngineRef.current.start();
      }else{
        window.location.reload();
      }
    }
    if (e.type === "fetchSecondPart") {
      setFetchinSecondPart(true);
      if (!fetchinSecondPart) {
        const secondPart = await fetch(
          "https://api.cryptodromo.com/race/exact/results"
        );
        let raceData = await secondPart.json();
        raceData.participants = lodash.sortBy(raceData.participants, "gate");

        raceData.participants.forEach((horse) => {
          horse.stats = _.orderBy(horse.stats, "timeStart", "desc");
          horse.stats.forEach((dose) => {
            dose.slope = calculateSlope(
              dose.positionStart * 60,
              dose.positionEnd * 60
            );
          });
          horse.stats.forEach((dose) => {
            dose.slope = dose.slope * 1;
          });
          horse.stats[0].slope = horse.stats[1].slope;
        });
        setRaceData(raceData);
      }
    }
    if (e.type === "transition") {
      setTransitioning(true);
      if (!transitioning) {
        const data = await fetch(
          "https://api.cryptodromo.com/race/exact/results"
        );
        let raceData = await data.json();
        raceData.participants = lodash.sortBy(raceData.participants, "gate");
        raceData.participants.forEach((horse) => {
          horse.stats = _.orderBy(horse.stats, "timeStart", "desc");
          horse.stats.forEach((dose) => {
            dose.slope = calculateSlope(
              dose.positionStart * 60,
              dose.positionEnd * 60
            );
          });
          horse.stats.forEach((dose) => {
            dose.slope = dose.slope * 1;
          });
          horse.stats[0].slope = horse.stats[1].slope;
        });
        setRaceData(raceData);
        let diffTime = differenceInMilliseconds(
          new Date(),
          new Date(raceData.start)
        );

        if (window.gameEngineRef.current) {
          window.gameEngineRef.current.stop();
          diffTime = diffTime - 20000;
          timer.setStartingTime(-diffTime);
          setStartTime(-diffTime);
          await window.gameEngineRef.current.swap(
            Entities(raceData, {
              tribuna: searchParams.get("tribuna") === "no" ? false : true,
              stadium,
            })
          );
        }
        setFetchinSecondPart(false);
        setTransitioning(false);
      }
    }
  };

  window.toggleMute = () => {
    if (theSound.playing()) {
      theSound.mute(!isMutted);
      soundtrack2.mute(!isMutted);
      window.soundOn = isMutted;
      setIsMutted(!isMutted);
    } else {
      soundtrack2.seek(0);
      theSound.seek((timer.getCurrentTime() + 5000) / 1000);
      setIsMutted(!isMutted);
      window.soundOn = true;
      theSound.play();
      soundtrack2.play();
    }
  };
  useMemo(() => {
    if (!raceData) {
      return null;
    }
    setEntities(
      Entities(raceData, {
        tribuna: searchParams.get("tribuna") === "no" ? false : true,
        startTime: -startTime,
        stadium,
      })
    );
    window.data = raceData;
  }, [raceData, startTime]);

  if (!raceData) {
    return null;
  }

  return (
    <>
      <NextRaceDataContext.Provider value={nextRaceData}>
        <RaceDataContext.Provider value={raceData}>
          <AnimatePresence initial={true}>
            <motion.div
              key="modalStartStats"
              initial={{ opacity: 0 }}
              animate={transitioning ? { opacity: 0 } : { opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 2, ease: "easeOut" }}
              style={{
                aspectRatio: "16/9",
                maxWidth: "100%",
                maxHeight: "100%",
                minWidth: "320px",
                minHeight: "180px",
                margin: "auto",
                fontSize: "100%",
              }}
              ref={refContainer}
            >
              <GameEngine
                className="game"
                systems={Systems}
                timer={timer}
                onEvent={handleEvent}
                ref={gameEngineRef}
                entities={entities}
                renderer={Renderer()}
                style={{
                  position: "relative",
                }}
              />
            </motion.div>
          </AnimatePresence>
        </RaceDataContext.Provider>
      </NextRaceDataContext.Provider>
    </>
  );
};

export default Game;
