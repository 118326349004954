export const styles = {
  hud: {
    fontSize: 20,
    color: "white",
    zIndex: 100,
    width: "90%",
    padding: "55px 50px 15px 50px",
    height: "90%",
    position: "absolute",
    left: "5%",
    top: "2.5%",
    boxSizing: "border-box",
    backgroundColor: "rgb(0, 0, 0,0.95)",
    display: "grid",
    gridTemplateRows: "9% 76% 15%",
    fontSize: "1.900vw",
    borderRadius:"18px"
  },
  RaceTittle: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "700",
    alignItems:"start",
    gap:"15px",
    marginLeft:"1.4%"
  },
  RaceContent: {
    display: "grid",
    gridTemplateColumns: "15% 85%",
  },
  horseBall: (primaryColor, secondaryColor) => ({
    backgroundColor: `#${primaryColor}`,
    border: `0.122vw solid #${secondaryColor}`,
    width: "1.266vmin",
    boxShadow: "-0.122vw 0px 3px #303030",
    height: "1.266vmin",
    borderRadius: "100%",
  }),

  nextRaceFooter: {
    display: "grid",
    gridTemplateColumns: "79% 21%",
    alignItems: "center",
    justifyItems: "end"
  },
  specialRace: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap:"10px",
  },
  nexRaceCooldown: {
    color: "white",
    fontWeight: "700",
    letterSpacing: "0.05rem",
    fontSize: "1.900vw",
  },
  stakeButton: {
    height: "45%",
    width: "90%",
    color: "white",
    background: "#479747",
    borderRadius: "30px",
    border: "solid transparent 1px",
    fontFamily: "Segoe UI",
    cursor: "pointer",
    fontSize: "0.852vw",
  },
};
