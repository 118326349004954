import React, { useState, useEffect } from "react";

const RacesList = () => {
  const [races, setRaces] = useState([]);
  useEffect(() => {
    fetchRace();
  }, []);
  const fetchRace = async () => {
    //1210
    const data = await fetch(`http://api.cryptodromo.com/races`);
    const listData = await data.json();
    setRaces(listData);
  };

  return (
    <>
      <ul>
        {races.map((race) => {
          return (
            <li>
              <a href={`/?raceId=${race.id}`}>
                {race.id} Reprocesada de {race.reprocessed || "Ninguna"} -
                Desde: {race.start} - Hasta: {race.end}
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default RacesList;
